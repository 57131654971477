<template>
  <div class="form-wrapper">
    <el-dialog :title="$t('Select Customer')" :visible.sync="displayCustomerForm" width="60%">
      <SelectCustomer @customerSelected="updateCustomer"/>
    </el-dialog>
    <el-dialog :title="$t('Select Order')" :visible.sync="displayOrderForm" width="60%">
      <SelectOrder @orderSelected="updateOrder"/>
    </el-dialog>

    <div class="action-button-wrapper">
      <button class="btn btn-primary saveButton" @click="save">{{ $t('Save')}}</button>
    </div>
    <div class="document-infomration">
      <el-card class="box-card related-order-card">
        <div slot="header" class="clearfix">
          <span>{{ $t('Related Order') }}</span>
        </div>
        <el-form class="related-order-form" ref="related-order-form" :model="form" label-width="150px" label-position="left">
          <div class="row related-order-checkbox">
            <el-checkbox v-model="hadRelatedOrder" @change="onCheckRelatedOrder">{{ $t('Had related order') }}</el-checkbox>
          </div>
          <div class="row selectOrderButton" v-if="hadRelatedOrder">
            <el-button @click="activeSelectOrder"> {{ $t('Select Order') }} </el-button>
          </div>
        </el-form>
        <table class="table table-striped" v-if="hadRelatedOrder">
          <thead>
            <tr>
              <th scope="col">{{ $t('Order No.') }}</th>
              <th scope="col">{{ $t('Date') }}</th>
              <th scope="col">{{ $t('Order Status') }}</th>
              <th scope="col">{{ $t('Total') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="selectedOrder">
              <td>{{ selectedOrder.reference_id }}</td>
              <td>{{ formatDateTime(selectedOrder.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td>{{ $t(selectedOrder.order_status) }}</td>
              <td>HK$ {{ loadOrderAmount(selectedOrder) }}</td>
            </tr>
          </tbody>
        </table>
      </el-card>

      <el-card class="box-card related-customer-card" v-if="!hadRelatedOrder">
        <div slot="header" class="clearfix">
          <span>{{ $t('Customer information') }}</span>
        </div>
        <el-form class="related-customer-form" ref="related-customer-form" :model="form" label-width="150px" label-position="left">
          <div class="row">
            <el-button @click="activeSelectCustomer"> {{ $t('Select Customer') }} </el-button>
            <el-button @click="activeCreateCustomer"> {{ $t('Create Customer') }} </el-button>
          </div>
          <div class="row create-customer-form" v-if="createCustomer || this.form.customer">
            <div class="row" style="width: 80%">
              <el-form-item :label="$t('Name')" style="max-width: 80%">
                <el-input :disabled="selectCustomer" v-model="form.customer.name"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item class="email" :label="$t('Email')" style="max-width: 80%">
                <el-input :disabled="selectCustomer" v-model="form.customer.email"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Phone Number')" style="max-width: 80%">
                <el-input :disabled="selectCustomer" v-model="form.customer.phone"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-card>

      <el-card class="box-card related-product-card" v-if="!hadRelatedOrder">
        <div slot="header" class="clearfix">
          <span>{{ $t('Select Products') }}</span>
        </div>
        <el-form class="related-product-form" ref="related-product-form" :model="form" label-width="150px" label-position="left">
          <div class="row" style="width: 80%">
            <el-form-item :label="$t('Products')" style="max-width: 80%">
              <el-select v-if="langcode === 'en'" v-model="form.relatedProducts" filterable multiple :placeholder="$t('Please Select')" @change="updateTotalAmount">
                <el-option
                  v-for="product in products"
                  :key="product.id"
                  :label="product.product_name_en"
                  :value="product.id">
                </el-option>
              </el-select>
              <el-select v-else v-model="form.relatedProducts" filterable multiple :placeholder="$t('Please Select')" @change="updateTotalAmount">
                <el-option
                  v-for="product in products"
                  :key="product.id"
                  :label="product.product_name_zh"
                  :value="product.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div class="amount-content">
          {{ $t('Total Amount')}}: HK${{ totalAmount }}
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Product from '@/lib/product';
import SelectCustomer from '@/components/order/SelectCustomer';
import SelectOrder from '@/components/document/SelectOrder';

export default {
  name: 'DocumentForm',
  components: {
    SelectCustomer,
    SelectOrder,
  },
  mounted(){
    this.loadProducts();
  },
  data(){
    //Default form value
    let form = {
      orderId: null,
      customerId: null,
      customer: null,
      relatedProducts: [],
    };

    return {
      form,
      hadRelatedOrder: false,
      displayCustomerForm: false,
      displayOrderForm: false,
      selectCustomer: false,
      createCustomer: false,
      selectedOrder: null,
      totalAmount: 0,
      products: [],
    };
  },
  methods:{
    async loadProducts(){
      try{
        const loginInfo = Common.getLoginInfo();
        const products = await Product.loadAllProducts(this.apiUrl, loginInfo);
        this.products = products;
      }catch(err){
        console.log(err);
      }
    },
    loadOrderAmount(order){
      const relatedProducts = JSON.parse(order.related_product);
      const productInfos = order.product_infos;
      const totalAmount = this.loadItemAmount(productInfos, relatedProducts);
      return totalAmount;
    },
    loadItemAmount(productInfos, relatedProducts){
      let totalAmount = 0;
      for(let i = 0; i < relatedProducts.length; i++ ){
        const currentProduct = relatedProducts[i];
        const productInfo = productInfos.find(function(item){
          return currentProduct.id === item.id;
        });
        const productPrice = Number(productInfo['product_regular_price']);
        totalAmount += productPrice;
      }
      return totalAmount;
    },
    getTotalAmount(){
      let totalAmount = 0;
      const selectProducts = this.form.relatedProducts;
      for(let i = 0; i < selectProducts.length; i++){
        const productId = selectProducts[i];
        const product = this.products.find(function(item){
          return item.id === productId;
        });
        const productPrice = Number(product.product_price);
        totalAmount += productPrice;
      }
      return totalAmount;
    },
    updateTotalAmount(){
      const totalAmount = this.getTotalAmount();
      this.totalAmount = totalAmount;
    },
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    activeSelectCustomer(){
      //Clear the form customer first
      this.form.customer = null;
      this.displayCustomerForm = true;
      this.createCustomer = false;
      this.selectCustomer = true;
    },
    activeCreateCustomer(){
      this.form.customer = {
        'name': '',
        'email': '',
        'phone': '',
      };
      this.selectCustomer = false;
      this.createCustomer = true;
    },
    activeSelectOrder(){
      this.displayOrderForm = true;
    },
    updateOrder(order){
      this.form.orderId = order.id;
      this.selectedOrder = order;
      this.displayOrderForm = false;
    },
    updateCustomer(customer){
      this.form.customer = {
        'name': customer.customer_name,
        'email': customer.customer_email,
        'phone': customer.customer_phone,
      };
      this.form.customerId = customer.id;
      this.displayCustomerForm = false;
    },
    onCheckRelatedOrder(value){
      if(value === false){
        this.form.orderId = null;
        this.selectedOrder = null;
      }else{
        this.form.relatedProducts = null;
        this.totalAmount = 0;
      }
    },
    save(){
      this.$emit('action', this.form);
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.document{
  .action-button-wrapper{
    text-align: right;
    margin-bottom: 50px;
  }
  .form-wrapper{
    padding-left: 20px;
  }
  .document-form{
    padding-left: 20px;
  }
  .el-card__header{
    background-color: black;
    color: white;
  }
  .el-form-item.email{
    margin-right: 30px;
  }
  .document-orders-card{
    margin-top: 50px;
  }
  .related-customer-form{
    padding-left: 20px;
  }
  .create-customer-form{
    margin-top: 50px;
    padding-left: 20px;

    .email{
      margin-right: 50px;
    }
  }
  .related-order-checkbox{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .related-customer-card, .related-order-card, .related-product-card{
    margin-top: 50px;
  }
  .related-order-form, .related-product-form{
    padding-left: 20px;
  }
  .selectOrderButton{
    margin-top: 10px;
    margin-bottom: 30px;
  }
}
</style>
